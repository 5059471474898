//import * as React from "react";
import * as ReactDOM from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import './components/styles.css';
import './index.css';
import App from './App.js';

ReactDOM.render(
//    <BrowserRouter>
        <div className="App">
            <App />
        </div>,
//    </BrowserRouter>,
document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
