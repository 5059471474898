import * as React from 'react';
import { Box } from '@material-ui/core';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import "./styles.css";


export default function BidOptimization() {
  const [values, setValues] = React.useState({
    initialBid: "",
    maxBid: "",
    ecpi: ""
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

    return (
      <div className="App">
        <div>
        <div
            style={{marginTop:20}}
            ></div>
          <Grid
                container
                direction="column"
                alignItems="center"
                >
                {/* <FormControl component="fieldset"> */}
                <FormLabel component="legend"></FormLabel>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column"}}>
  {/*}
                '& > :not(style)': { m: 1, width: '30ch' },
              }}
              noValidate
              autoComplete="off"
            >*/}
            <div style={{marginBottom:20}}>
              <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel shrink htmlFor="outlined-adornment-initialBid">Initial Bid (CPM)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-initialBid"
                value={values.initialBid}
                onChange={handleChange("initialBid")}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                inputlabelprops={{shrink: true}}
              />
              </FormControl>
            </div>
            <div style={{marginBottom:20}}>
              <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel shrink htmlFor="outlined-adornment-maxBid">Max Bid (CPM)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-maxBid"
                value={values.maxBid}
                onChange={handleChange("maxBid")}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                inputlabelprops={{shrink: true}}
              />
              </FormControl>
            </div>
            <div style={{marginBottom:20}}>
              <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel shrink htmlFor="outlined-adornment-ecpi">eCPI Target</InputLabel>
              <OutlinedInput
                id="outlined-adornment-ecpi"
                value={values.ecpi}
                onChange={handleChange("ecpi")}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                inputlabelprops={{shrink: true}}
              />
              </FormControl>
            </div>
          </Box>
        </Grid>
        {console.log("initial-bid: " + values.initialBid)}
        {console.log("max-bid: " + values.maxBid)}
        {console.log("ecpi-target: " + values.ecpi)}
      </div>
    </div>
    );
  }
