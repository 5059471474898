import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import { useButton } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';

const ReportingButtonRoot = styled('button')`
  background-color: #11254F;
  padding: 10px 10px;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  font-family: inherit;
  font-size: 12px;
  transition: all 200ms ease;
  cursor: pointer;
 /* box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0); */
  border: 1px solid #fff;

  &:hover {
    background-color: #11254F;
    opacity: 0.6;
  }

  &.active {
    background-color: #11254F;
    opacity: 0.6;
  }

`;


const ReportingButton = React.forwardRef(function ReportingButton(props, ref) {
    const { children } = props;
    const { active, focusVisible, getRootProps } = useButton({
      ...props,
      ref,
      component: ReportingButtonRoot,
    });

    const classes = {
      active,
      focusVisible,
    };

    return (
      <ReportingButtonRoot {...getRootProps()} className={clsx(classes)}>
        {children}
      </ReportingButtonRoot>
    );
  });

  ReportingButton.propTypes = {
    children: PropTypes.node,
  };

export default function ReportingButtons() {
  return (
    <Stack
        direction="row"
        spacing={2}
        >
      <ReportingButton href="#reporting-button">REPORTING</ReportingButton>
    </Stack>
  );
}