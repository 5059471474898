// @flow

import * as React from "react";
//import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core/styles";
//import blue from "@material-ui/core/colors/blue";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./styles.css";

import Grid from '@material-ui/core/Grid';

const ExclusionList = props => {

  const [exclusions, setExclusions] = React.useState(['']);
    // eslint-disable-next-line
    const { classes } = props;
    console.log(exclusions);
    function toggleSet(value) {
      console.log(exclusions.indexOf(value));
      if (exclusions.indexOf(value) >= 0) {
        console.log(exclusions.indexOf(value));
        setExclusions(exclusions.filter(excl => excl !== value));
      } else {
        setExclusions(exclusions.concat(value));
      }
    }

    function getValue(value) {
      return exclusions.indexOf(value) >= 0;
    }

return (
  <div className = "App">
      <div>
      <div style={{marginTop:10}}></div>
        <Grid
            container
            direction="column"
            alignItems="center"
          >
          <FormGroup column="true">
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("black-list")}
                  onChange={() => toggleSet("black-list")}
                  value="black-list"
                />
              }
              label="Black List"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("deal-id")}
                  onChange={() => toggleSet("deal-id")}
                  value="deal-id"
                />
              }
              label="Deal Id"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("user-segments")}
                  onChange={() => toggleSet("user-segments")}
                  value="user-segments"
                />
              }
              label="User Segments"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("ip-exclusions")}
                  onChange={() => toggleSet("ip-exclusions")}
                  value="ip-exclusions"
                />
              }
              label="IP Exclusions"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("frequency-cap")}
                  onChange={() => toggleSet("frequency-cap")}
                  value="frequency-cap"
            />
              }
              label="Frequency Cap"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("device-lists")}
                  onChange={() => toggleSet("device-lists")}
                  value="device-lists"
                />
              }
              label="Device Lists"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue("zipcode-lists")}
                  onChange={() => toggleSet("zipcode-lists")}
                  value="zipcode-lists"
                />
              }
              label="Zip Code Lists"
            />
          </FormGroup>
          </Grid>
          <div style={{marginBottom:20}}></div>
        </div>
      </div>
  );
}

export default ExclusionList;