import * as React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import "./styles.css";

class Activation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: 'execute'};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    console.log(this.state.value)
    event.preventDefault();
  }

  render() {
    return (
      <div className = "App">
        <div>
        <div style={{marginTop:20}}></div>
          <Grid
              container
              direction="column"
              alignItems="center"
              >
              <FormControl onSubmit={() => this.state.value.name}>
              <FormLabel component="legend"></FormLabel>
              <RadioGroup
                  aria-label="activation"
                  name="activation"
                  value={this.state.value}
                  onChange={this.handleChange}
              >
                  <FormControlLabel id="execute" name="execute" value="execute" control={<Radio />} label="Execute" />
                  <FormControlLabel id="stop-pause" name="stop-pause" value="stop-pause" control={<Radio />} label="Stop/Pause" />
                  <FormControlLabel id="delete-archive" name="delete-archive" value="delete-archive" control={<Radio />} label="Delete/Archive" />

                  {console.log(this.state.value)}
              </RadioGroup>
              </FormControl>
              <div style={{marginBottom:20}}></div>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Activation;
