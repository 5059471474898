import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class SelectInventorySource extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">

        <FormControl
        fullWidth={true}
        multiselect="true"

        onSubmit={this.handleSubmit}>
          <InputLabel>Inventory Source</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
          <MenuItem value="adf">Adform</MenuItem>
          <MenuItem disabled value="adl">Adlantic</MenuItem>
          <MenuItem disabled value="ado">ADOP</MenuItem>
          <MenuItem disabled value="adv">AdView</MenuItem>
          <MenuItem value="adx">Google AdX</MenuItem>
          <MenuItem value="ae">Aerserv</MenuItem>
          <MenuItem value="ame">Admedia</MenuItem>
          <MenuItem disabled value="ami">Admixer</MenuItem>
          <MenuItem disabled value="amz">Admized</MenuItem>
          <MenuItem value="anx">Xandr – (Monetize-SSP) AppNexus</MenuItem>
          <MenuItem value="ap">AdPrime</MenuItem>
          <MenuItem value="apl">AppLovin</MenuItem>
          <MenuItem disabled value="apv">Appvador</MenuItem>
          <MenuItem disabled value="asp">Adspruce</MenuItem>
          <MenuItem value="asr">Adstir</MenuItem>
          <MenuItem disabled value="asu">Adsupply</MenuItem>
          <MenuItem disabled value="aun">AdUnity</MenuItem>
          <MenuItem value="axo">Axonix</MenuItem>
          <MenuItem disabled value="az">Adbuddiz</MenuItem>
          <MenuItem disabled value="bdf">Bidswitch Deals Feed</MenuItem>
          <MenuItem disabled value="bid">Bidtellect Select</MenuItem>
          <MenuItem disabled value="cau">Cauly</MenuItem>
          <MenuItem disabled value="cnv">Converge Digital</MenuItem>
          <MenuItem disabled value="cou">Coull</MenuItem>
          <MenuItem disabled value="cx">C1X</MenuItem>
          <MenuItem disabled value="dsh">Dish</MenuItem>
          <MenuItem value="dtm">DistrictM</MenuItem>
          <MenuItem value="emx">EMX</MenuItem>
          <MenuItem disabled value="exe">ExelBid</MenuItem>
          <MenuItem disabled value="exp">Experian</MenuItem>
          <MenuItem disabled value="fid">Fidelity</MenuItem>
          <MenuItem disabled value="flo">Floor 6</MenuItem>
          <MenuItem value="gam">Gamut (Cox)</MenuItem>
          <MenuItem disabled value="gl">Glispa Connect</MenuItem>
          <MenuItem disabled value="gmo">GMO</MenuItem>
          <MenuItem disabled value="gum">GumGum</MenuItem>
          <MenuItem value="ia">Fyber/Inneractive</MenuItem>
          <MenuItem value="im">Improve Digital</MenuItem>
          <MenuItem value="inm">Inmobi</MenuItem>
          <MenuItem value="is">IronSource</MenuItem>
          <MenuItem disabled value="kar">Kargo</MenuItem>
          <MenuItem disabled value="krx">Krux</MenuItem>
          <MenuItem disabled value="lem">Lemma</MenuItem>
          <MenuItem disabled value="lg">LG</MenuItem>
          <MenuItem disabled value="lig">Ligatus</MenuItem>
          <MenuItem disabled value="loo">LoopMe</MenuItem>
          <MenuItem disabled value="mad">MADS</MenuItem>
          <MenuItem disabled value="mbs">Mobsmart</MenuItem>
          <MenuItem disabled value="mcr">Microad</MenuItem>
          <MenuItem disabled value="ml">MaxAds</MenuItem>
          <MenuItem value="mm">Millennial Media</MenuItem>
          <MenuItem disabled value="mo">Yieldmo</MenuItem>
          <MenuItem disabled value="mob">Mobfox</MenuItem>
          <MenuItem disabled value="moc">Mocoplex</MenuItem>
          <MenuItem value="mp">MoPub</MenuItem>
          <MenuItem disabled value="nas">NasMedia</MenuItem>
          <MenuItem disabled value="nta">NativeAds</MenuItem>
          <MenuItem value="om">Opera Mediaworks</MenuItem>
          <MenuItem value="out">Outbrain</MenuItem>
          <MenuItem value="ox">OpenX</MenuItem>
          <MenuItem value="pbn">PubNative</MenuItem>
          <MenuItem disabled value="per">Personagraph</MenuItem>
          <MenuItem disabled value="pin">DeepIntent</MenuItem>
          <MenuItem disabled value="pok">POKKT</MenuItem>
          <MenuItem value="pp">PulsePoint</MenuItem>
          <MenuItem disabled value="ptx">ProfitX</MenuItem>
          <MenuItem disabled value="pw">Phunware</MenuItem>
          <MenuItem value="px">PilotX</MenuItem>
          <MenuItem value="ra">RichAudience</MenuItem>
          <MenuItem value="ro">RhythmOne</MenuItem>
          <MenuItem value="rp">Magnite DV+ (Rubicon)</MenuItem>
          <MenuItem disabled value="sar">SARA</MenuItem>
          <MenuItem value="sas">SmartAdServer</MenuItem>
          <MenuItem disabled value="sco">Scaleout</MenuItem>
          <MenuItem value="sm">Smaato</MenuItem>
          <MenuItem value="smy">SmartyAds</MenuItem>
          <MenuItem disabled value="srt">Sortable</MenuItem>
          <MenuItem disabled value="str">Ströer (AdScale)</MenuItem>
          <MenuItem disabled value="svr">Sovrn (FMX)</MenuItem>
          <MenuItem disabled value="tab">Taboola</MenuItem>
          <MenuItem disabled value="ten">TenMax</MenuItem>
          <MenuItem disabled value="thr">Throne (Barons Media)</MenuItem>
          <MenuItem disabled value="trl">TripleLift</MenuItem>
          <MenuItem disabled value="trx">TrustX</MenuItem>
          <MenuItem disabled value="tvp">TheViewPoint</MenuItem>
          <MenuItem disabled value="ucf">UCFunnel</MenuItem>
          <MenuItem value="uty">Unity</MenuItem>
          <MenuItem disabled value="vi">Vi</MenuItem>
          <MenuItem disabled value="vmx">VMAX</MenuItem>
          <MenuItem disabled value="vrt">VRTCAL</MenuItem>
          <MenuItem value="vrv">Verve</MenuItem>
          <MenuItem disabled value="woo">Woobi</MenuItem>
          <MenuItem disabled value="yda">YieldOne ADS</MenuItem>
          <MenuItem value="ydl">Yieldlab</MenuItem>
          <MenuItem disabled value="zt">Zeotap</MenuItem>
          </Select>
        </FormControl>

        {console.log("inventory_source:" + this.state.value)}
      </div>
    );
  }
}

export default SelectInventorySource;