import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class DspNetworks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">

        <FormControl
        fullWidth={true}
        onSubmit={this.handleSubmit}>
          <InputLabel>DSP Networks</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
            <MenuItem value="beeswax">Beeswax</MenuItem>
            <MenuItem value="centro">Centro</MenuItem>
            <MenuItem value="bucksense">Bucksense</MenuItem>
            <MenuItem value="adtelligent">Adtelligent</MenuItem>
            <MenuItem disabled value="amazondsp">Amazon DSP</MenuItem>
            <MenuItem disabled value="adkernel">Ad Kernel</MenuItem>
            <MenuItem disabled value="inmobi-dsp">in Mobi DSP</MenuItem>
            <MenuItem disabled value="kayzen-dsp">Kayzen DSP</MenuItem>
            <MenuItem disabled value="media-math">Media Math</MenuItem>
            <MenuItem disabled value="nextroll">Next Roll</MenuItem>
            <MenuItem disabled value="trade-desk">The Trade Desk</MenuItem>
            <MenuItem disabled value="verizon-media">Verizon Media</MenuItem>
            <MenuItem disabled value="yandex">Yandex</MenuItem>
            <MenuItem disabled value="xandr">Xandr</MenuItem>
            <MenuItem disabled value="magnite">Magnite</MenuItem>
            <MenuItem disabled value="smart-adserver">Smart Ad Server</MenuItem>
            <MenuItem disabled value="smaato">Smaato</MenuItem>
            <MenuItem disabled value="pubnative">Pubnative</MenuItem>
            <MenuItem disabled value="pubmatic">Pubmatic</MenuItem>
            <MenuItem disabled value="mopub">Mopub</MenuItem>
            <MenuItem disabled value="kevel">Kevel</MenuItem>
            <MenuItem disabled value="iponweb">Ipon Web</MenuItem>
            <MenuItem disabled value="Index Exchange">Index Exchange</MenuItem>
            <MenuItem disabled value="getintent-dsp">GetIntent DSP</MenuItem>
            <MenuItem disabled value="criteo">Criteo</MenuItem>
            <MenuItem disabled value="chartboost">Chartboost</MenuItem>
            <MenuItem disabled value="moloco">Moloco</MenuItem>
            <MenuItem disabled value="mintegral">Mintegral</MenuItem>
            <MenuItem disabled value="pubmatic">Pubmatic</MenuItem>
            <MenuItem disabled value="pubmatic">Pubmatic</MenuItem>
            <MenuItem disabled value="pubmatic">Pubmatic</MenuItem>
            <MenuItem disabled value="pubmatic">Pubmatic</MenuItem>

          </Select>
        </FormControl>

        {console.log("dsp_network:" +this.state.value)}
      </div>
    );
  }
}

export default DspNetworks;