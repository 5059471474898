// @flow

import "./styles.css";

import React, { Fragment } from "react";
//import { makeStyles } from "@material-ui/core/styles";
//import TextField from "@material-ui/core/TextField";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
//import Select from "@material-ui/core/Select";
//import InputLabel from "@material-ui/core/InputLabel";
//import MenuItem from "@material-ui/core/MenuItem";
//import FormControl from "@material-ui/core/FormControl";
//import Rating from "@material-ui/lab/Rating";
//import StarBorderIcon from "@material-ui/icons/StarBorder";
import "./styles.css";

// eslint-disable-next-line
//import { uniqBy, range } from "lodash";
//import { Checkbox } from "@material-ui/core";
//import { title } from "process";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  rating: {
    maxWidth: 100
  }
})); */

class SearchCreatives extends React.Component {
  constructor(){
    super();
    this.state = {
      optionsdata : [
        {key: '101', value: 'Creative A'},
        {key: '102', value: 'Creative B'},
        {key: '103', value: 'Creative C'},
        {key: '104', value: 'Creative D'},
        {key: '105', value: 'Creative E'},
      ]
    }
  }
  handleChange = (event) => {
    console.log(event.target.value);
    var value = this.state.optionsdata.filter(function(item) {
      return item.key === event.target.value
    })
    console.log(value[0].value);
  }
  render() {
    return (
      <Fragment>
        <div style={{marginTop:20}}></div>
        <Grid
          container
          direction="column"
          alignItems="center"
         >
          <Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="row" spacing={1}>
                <Grid item xs>
                <select
                onChange={this.handleChange}
                  >
                {this.state.optionsdata.map(function(data, key){ return (
                  <option key={key} value={data.key}>{data.value}</option> )
                })}
                </select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        <div style={{marginBottom:20}}></div>
      </Grid>
    </Fragment>
    )
  }
}


export default SearchCreatives;
