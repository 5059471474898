// @flow

import React, { Fragment } from 'react';
import './components/styles.css';
import './components/styles.css';
import '/Users/brettbauer1/dev_projects/campaign-setup/src/App.css';
//import { Box, makeStyles } from '@material-ui/core';
import ExclusionList  from './components/ExclusionList';
import TargetingCard from './components/TargetingCard';
import CampaignSetup from './components/CampaignSetup';
import CampaignInformation from './components/CampaignInformation';
import CampaignDates from './components/CampaignDates';
import Activation from './components/Activation';
import Optimization from './components/Optimization';
import CampaignOptimization from './components/CampaignOptimization';
import BidOptimization from './components/BidOptimization';
import Budget from './components/Budget';
import SearchCreatives from './components/SearchCreatives';
import ExecuteButton from './components/ExecuteButton';
import TopAppBar from './components/TopAppBar';
import StickyFooter from './components/StickyFooter';

// eslint-disable-next-line
export default function App() {

  return (
    <Fragment>
        <header className="mdc-top-app-bar">
          <TopAppBar />
        </header>
      {/*  <!-- End of Fixed Header with Menu --> */}

      {/* <!-- Start of Column One --> */}
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined" name="campaignSetup">
                <div className="mdc-card__primary-action">
                  <div className="mdc-layout-grid--span-12">
                  <hr></hr>
                  <h6 className="mdc-typography--headline6">Campaign Setup</h6>
                  <hr></hr>
                  <div className="mdc-touch-target-wrapper">
                    <CampaignSetup />
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined" name="campaignInformation">
                <div className="mdc-card__primary-action">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Campaign Information</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <CampaignInformation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined" name="campaignInformation">
                <div className="mdc-card__primary-action">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Campaign Dates</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <CampaignDates />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Activation</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <Activation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Optimization</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <Optimization />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Campaign Optimization</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <CampaignOptimization />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Bid Optimization</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <BidOptimization />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Budget</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <Budget />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Creatives</h6>
                    <hr></hr>
                    <div className="mdc-touch-target-wrapper">
                      <SearchCreatives />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined" name="campaignSetup">
                <div className="mdc-card__primary-action">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Exclusions</h6>
                    <hr></hr>
                    <div>
                    <div className="mdc-touch-target-wrapper">
                      <ExclusionList />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <div className="mdc-card mdc-card--outlined" name="targeting">
                  <div className="mdc-card__primary-action">
                    <div className="mdc-layout-grid--span-12">
                      <hr></hr>
                      <h6 className="mdc-typography--headline6">Targeting</h6>
                      <hr></hr>
                      <div className="mdc-touch-target-wrapper">
                      <div>
                        <TargetingCard />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <div className="mdc-card mdc-card--outlined" name="execution">
                  <div className="mdc-card__primary-action">
                    <div className="mdc-layout-grid--span-12">
                      <hr></hr>
                      <h6 className="mdc-typography--headline6">Start Campaigns</h6>
                      <hr></hr>
                      <div className="mdc-touch-target-wrapper">
                      <div>
                        <ExecuteButton />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className="mdc-card mdc-card--outlined">
                <div className="mdc-card__primary-action" tabIndex="0">
                  <div className="mdc-layout-grid--span-12">
                    <hr></hr>
                    <h6 className="mdc-typography--headline6">Execution Status</h6>
                    <hr></hr>
                    <div style={{marginTop:20}}></div>
                      <div className="mdc-touch-target-wrapper">
                      <div className="mdc-form-field" >
                        <table className="mdl-data-table mdl-js-data-table">
                          <thead>
                            <tr>
                              <th className="mdl-data-table__cell--non-numeric">Setup</th>
                              <th className="mdl-data-table__cell--non-numeric">Completed</th>
                              <th className="mdl-data-table__cell--non-numeric">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="mdl-data-table__cell--non-numeric">Sandbox</td>
                              <td className="mdl-data-table__cell--non-numeric">N/A</td>
                              <td className="mdl-data-table__cell--non-numeric">No Status</td>
                            </tr>
                            <tr>
                              <td className="mdl-data-table__cell--non-numeric">Production</td>
                              <td className="mdl-data-table__cell--non-numeric">N/A</td>
                              <td className="mdl-data-table__cell--non-numeric">No Status</td>
                            </tr>
                            <tr>
                              <td className="mdl-data-table__cell--non-numeric">Stopped/Paused</td>
                              <td className="mdl-data-table__cell--non-numeric">N/A</td>
                              <td className="mdl-data-table__cell--non-numeric">No Status</td>
                            </tr>
                            <tr>
                              <td className="mdl-data-table__cell--non-numeric">Deleted/Archived</td>
                              <td className="mdl-data-table__cell--non-numeric">N/A</td>
                              <td className="mdl-data-table__cell--non-numeric">No Status</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><div style={{marginBottom:20}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <footer>
        <StickyFooter />
      </footer>
    </Fragment>
  );
}