import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import { useButton } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import "./styles.css";

const ExecuteButtonRoot = styled('button')`
  background-color: #11254F;
  padding: 20px 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  transition: all 200ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #0059b2;
  }

  &.active {
    background-color: #004386;
  }

`;

const ExecuteButton = React.forwardRef(function ExecuteButton(props, ref) {
  const { children } = props;
  const { active, focusVisible, getRootProps } = useButton({
    ...props,
    ref,
    component: ExecuteButtonRoot,
  });

  const classes = {
    active,
    focusVisible,
  };

  return (
    <ExecuteButtonRoot {...getRootProps()} className={clsx(classes)}>
      {children}
    </ExecuteButtonRoot>
  );
});

ExecuteButton.propTypes = {
  children: PropTypes.node,
};

export default function UseButton() {
  return (
    <Stack
      spacing={2}
      direction="row"
      display="flex"
      justifyContent="center"
      paddingTop="15px"
      paddingBottom="20px"
      >
      <ExecuteButton onClick={() => console.log('click!')}>START CAMPAIGNS</ExecuteButton>
    </Stack>
  );
}
