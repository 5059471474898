import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class DeviceType extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <FormControl
        fullWidth={true}
        onSubmit={this.handleSubmit}>
          <InputLabel>Device Type</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="tablet">Tablet</MenuItem>
            <MenuItem disabled value="desktop">Desktop</MenuItem>
            <MenuItem disabled value="connected-tv">Connected TV</MenuItem>
            <MenuItem disabled value="set-top-box">Set Top Box</MenuItem>
            <MenuItem disabled value="games-console">Games Console</MenuItem>
          </Select>
        </FormControl>
        {console.log("device_type:" +this.state.value)}
      </div>
    );
  }
}

export default DeviceType;
