import * as React from 'react';
//import { Box } from '@material-ui/core';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
//import InputLabel from '@material-ui/core/InputLabel';
//import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
//import { TextField } from '@material-ui/core';
import StartDatePicker from './StartDatePicker';
import EndDatePicker from './EndDatePicker';
import "./styles.css";

export default function CampaignDates() {

 /*  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    console.log(this.state.value)
    event.preventDefault();
  } */

  // render() {
return (
  <div className="App">
  <div style={{marginTop:20}}></div>
    <Grid
      container
      direction="column"
      alignItems="center"
      >
      <div>
        <StartDatePicker />
      </div>
      <div style={{marginBottom:20}}></div>
      <div>
        <EndDatePicker />
      </div>
      <div style={{marginBottom:20}}></div>
      </Grid>
    </div>
  );
}
//}

//export default CampaignDates;
