import React from 'react';
import { Grid } from "@material-ui/core";
import DeviceOs from './DeviceOs';
import DeviceType from './DeviceType';
import DspNetworks from './DspNetworks';
import OsVersion from './OsVersion';
import Rewarded from './Rewarded';
import SdkNetworks from './SdkNetworks';
import SelectCountry from './SelectCountry';
import SelectInventorySource from './SelectInventorySource';
import "./styles.css";

import '/Users/brettbauer1/dev_projects/campaign-setup/src/App.css';

class TargetingCard extends React.Component {

  render() {
    return (
      <div>
        <Grid
            container
            direction="column"
            justifyContent='space-evenly'
            alignItems='stretch'
//            paddingright={20}
//           paddingleft={20}
          >
              <SelectCountry />
              <DeviceType />
              <DeviceOs />
              <OsVersion />
              <Rewarded />
              <SelectInventorySource />
              <DspNetworks />
              <SdkNetworks />
          </Grid>
          <div style={{marginBottom:20}}></div>
      </div>
    )
  };
}

export default TargetingCard;
