import * as React from 'react';

import Stack from '@mui/material/Stack';
//import AutomationButtons from './HeaderButtons/AutomationButtons';
//import CreativesButtons from './HeaderButtons/CreativesButtons';
//import HelpButtons from './HeaderButtons/HelpButtons';
import HomeButtons from './HeaderButtons/HomeButtons';
import ReportingButtons from './HeaderButtons/ReportingButtons';
import SegmentButtons from './HeaderButtons/SegmentButtons';
import TargetingButtons from './HeaderButtons/TargetingButtons';
import "./styles.css";

export default function TextButtons() {
  return (
    <React.Fragment>
      <Stack
          direction="row"
          spacing={2}
          >
        <HomeButtons />
        <TargetingButtons />
      {/*  <AutomationButtons /> */}
        <SegmentButtons />
     {/*   <CreativesButtons /> */}
        <ReportingButtons />
      {/*  <HelpButtons /> */}
      </Stack>
    </React.Fragment>
  );
}
