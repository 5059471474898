import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class OsVersion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <FormControl
        fullWidth={true}
        onSubmit={this.handleSubmit}
        >
          <InputLabel>OS Version</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
              <MenuItem value="ios10">iOS 10</MenuItem>
              <MenuItem value="ios11">iOS 11</MenuItem>
              <MenuItem value="ios12">iOS 12</MenuItem>
              <MenuItem value="ios13">iOS 13</MenuItem>
              <MenuItem value="ios14">iOS 14</MenuItem>
              <MenuItem value="ios15">iOS 15</MenuItem>
              <MenuItem value="android5">Android 5</MenuItem>
              <MenuItem value="android6">Android 6</MenuItem>
              <MenuItem value="android7">Android 7</MenuItem>
              <MenuItem value="android8">Android 8</MenuItem>
              <MenuItem value="android9">Android 9</MenuItem>
              <MenuItem value="android10">Android 10</MenuItem>
              <MenuItem value="android11">Android 11</MenuItem>
              <MenuItem value="android12">Android 12</MenuItem>
              <MenuItem disabled value="osx-10.10">OSX Yosemite</MenuItem>
              <MenuItem disabled value="osx-10.11">OSX El Capitan</MenuItem>
              <MenuItem disabled value="osx-10.12">OSX Sierra</MenuItem>
              <MenuItem disabled value="osx-10.13">OSX High Sierra</MenuItem>
              <MenuItem disabled value="osx-10.14">OSX Mojave</MenuItem>
              <MenuItem disabled value="osx-10.15">OSX Catalina</MenuItem>
              <MenuItem disabled value="osx-11.0">OSX Big Sur</MenuItem>
              <MenuItem disabled value="osx-12.0">OSX Monterey</MenuItem>
              <MenuItem disabled value="osx-10.7">OSX Lion</MenuItem>
              <MenuItem disabled value="osx-10.8">OSX Mountain Lion</MenuItem>
              <MenuItem disabled value="osx-10.9">OSX Mavericks</MenuItem>
              <MenuItem disabled value="playstation-os3">Playstation OS 3</MenuItem>
              <MenuItem disabled value="playstation-os4">Playstation OS 4</MenuItem>
              <MenuItem disabled value="tvos-10.1">tvOS 10.1</MenuItem>
              <MenuItem disabled value="tvos-10.2">tvOS 10.2</MenuItem>
              <MenuItem disabled value="tvos-10.3">tvOS 10.3</MenuItem>
          </Select>
        </FormControl>
        {console.log("os_version:" +this.state.value)}
      </div>
    );
  }
}

export default OsVersion;
