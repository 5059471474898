import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class SdkNetworks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <FormControl
        fullWidth={true}

        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        >
          <InputLabel>SDK Networks</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
            <MenuItem value="asa">Apple Search Ads</MenuItem>
            <MenuItem value="googleAds">Google Ads</MenuItem>
            <MenuItem disabled value="unity">Unity</MenuItem>
            <MenuItem disabled value="facebookNetwork">Facebook Network</MenuItem>
            <MenuItem disabled value="twitterAds">Twitter Ads</MenuItem>
            <MenuItem disabled value="tiktokMarketing">Tik Tok</MenuItem>
            <MenuItem disabled value="snapchatMarketing">Snapchat</MenuItem>
            <MenuItem value="ironSource">IronSource</MenuItem>
            <MenuItem disabled value="appLovin">AppLovin</MenuItem>
            <MenuItem value="vungle">Vungle</MenuItem>
          </Select>
        </FormControl>
        {console.log("sdk_network:" +this.state.value)}
      </div>
    );
  }
}

export default SdkNetworks;