import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

  class DeviceOs extends React.Component {

    constructor(props) {
      super(props);
      this.state = {value: ''};

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
      this.setState({value: event.target.value});
    }

    handleSubmit(event) {
      alert('You selected: ' + this.state.value);
      event.preventDefault();
    }

  render() {
    return (
      <div className="App">
      <div paddingright={20}></div>
        <FormControl
        fullWidth={true}
        alignItems="center"
        onSubmit={this.handleSubmit}>
          <InputLabel>Device OS</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
            <MenuItem value={"android"}>Android</MenuItem>
            <MenuItem value={"ios"}>Apple iOS</MenuItem>
            <MenuItem disabled value={"chrome-os"}>Chrome OS</MenuItem>
            <MenuItem value={"iPad-os"}>Apple iPadOS</MenuItem>
            <MenuItem disabled value={"osx"}>Apple Desktop OS</MenuItem>
            <MenuItem disabled value={"play-station-os"}>PlayStation OS</MenuItem>
            <MenuItem disabled value={"roku-os"}>Roku OS</MenuItem>
            <MenuItem disabled value={"samsung-proprietary"}>Samsung Proprietary</MenuItem>
            <MenuItem disabled value={"tizen"}>Tizen</MenuItem>
            <MenuItem disabled value={"tv-os"}>Apple tvOS</MenuItem>
            <MenuItem disabled value={"windows"}>Windows</MenuItem>
            <MenuItem disabled value={"web-os"}>WebOS</MenuItem>
          </Select>
        </FormControl>
        {console.log("device_os:" +this.state.value)}
      </div>
    );
  }
}

export default DeviceOs;
