import React from "react";
//import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import "./styles.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://appfluencer.com/">
        Shared2you, Inc.
      </Link>{"  2012-"}
      {new Date().getFullYear()}
      {". All Rights Reserved."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "25vh"
  },
  footer: {
    flex: 1,
    padding: theme.spacing(3, 2),
    marginTop: 0,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800]
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">
            Appfluencer® is a Registered Trademark of Shared2you, Inc.
          </Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
