import * as React from 'react';
//import { Box } from '@material-ui/core';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
//import InputLabel from '@material-ui/core/InputLabel';
//import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
//import StartDatePicker from './StartDatePicker';
//import EndDatePicker from './EndDatePicker';
import "./styles.css";

class CampaignInformation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    console.log(this.state.value)
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <div>
        <div
            style={{marginTop:20}}
            ></div>
        <Grid
          container
          direction="column"
          alignItems="center"
          >
{/*           <FormControl>
          <InputLabel htmlFor="cg_name"></InputLabel>
          <Input id="cg_name" aria-describedby="cg_name" />
          <FormLabel component="legend"></FormLabel> */}
{/*           <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '30ch' },
            }}
            noValidate
            autoComplete="off"
          > */}
            <TextField
              id="cg_name"
              label="Campaign Name"
              variant="outlined"
              value={this.state.value.cg_name}
              onChange={this.handleChange}
               />
            <div style={{marginBottom:20}}></div>
          {/* </FormControl> */}
          {console.log("cg_name: " + this.state.value)}
          </Grid>
        </div>
      </div>
    );
  }
}

export default CampaignInformation;
