import React from 'react';
import logo from '/Users/brettbauer1/dev_projects/campaign-setup/src/Assets/img/appfluencer-logo5-wtb.png';
import '/Users/brettbauer1/dev_projects/campaign-setup/src/App.css';
import TextButtons from './TextButtons';
import "./styles.css";

class TopAppBar extends React.Component {

  render() {
    return (
      <div>
          <div className="mdc-top-app-bar__row">
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
              <img src={logo} className="App-logo" alt="logo" />
            </section>
            <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
              <TextButtons />
            </section>
          </div>
      </div>
    )
  };
}

export default TopAppBar;