import React from "react";
import "./styles.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

class Rewarded extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('You selected: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">

        <FormControl
          fullWidth={true}
          onSubmit={this.handleSubmit}>
          <InputLabel>Rewarded/Not Rewarded</InputLabel>
          <Select value={this.state.value} onChange={this.handleChange}>
            <MenuItem value="rw">Rewarded</MenuItem>
            <MenuItem value="nrw">Not Rewarded</MenuItem>
          </Select>
        </FormControl>

        {console.log("rewarded:" +this.state.value)}
      </div>
    );
  }
}

export default Rewarded;
